@import url("variables.css");
:root {
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", system-ui,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Web", sans-serif;
}
p {
  font-size: 12px;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb:active {
  background-color: #555;
}

.theme__dark {
  background-color: #292929;
  color: #f1f1f1;
}
.theme__white {
  background-color: #f1f1f1;
  color: #292929;
}

/* targeted css */
.map_and_chart_wrapper {
  height: 100vh;
}

.map_component {
  height: 35%;
  margin: 0px 10px;
}
.map_component_sideview {
  height: 22%;
  margin: 0px 10px;
}
.map_component,
.map_component_sideview > div {
  border-radius: 8px;
}

.chart_component {
  height: 65%;
  overflow-y: auto;
  padding: 10px;
}
.chart_component_stageview {
  height: 78%;
  overflow-y: auto;
  padding: 10px;
}

#scrollableDiv {
  overflow-y: auto !important;
}

.dashboard_wrapper {
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
}

.dashboard_map_wrapper {
  height: 80%;
}

.user_avatar {
  background-color: #26a0fc;
  color: wheat;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* css overrides */
.tab_root_class > div:first-child {
  padding: 0;
  margin: 5px;
}

.ant-tabs-tab-btn {
  font-size: 12px;
  display: flex;
  align-items: center;
}
.ant-tabs-tab-btn > .ant-tabs-tab-icon > svg {
  height: 18px;
  width: 18px;
}
.map_tab_wrapper_no_stage_view
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  margin: 0px 4px;
  padding: 0;
}
.map_tab_wrapper
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-ink-bar {
  width: 40px !important;
  margin-left: 15px;
}
.ant-tabs-nav::before {
  border-bottom: none !important;
}
.filterAndViewButtons > .ant-btn-background-ghost {
  border: 1px solid rgba(140, 140, 140, 0.35) !important;
}
.filterAndViewButtons > .active_button_dark {
  /* background-color: #393e40; */
  background-color: #6f767e;
  /* font-weight: 500; */
}
.filterAndViewButtons > .active_button_white {
  /* background-color: #393e40; */
  background-color: #e5dfff;
  border-color: #5f41dd !important;
  color: #5f41dd !important;
  /* font-weight: 500; */
}
.filter__dropdown > ul {
  background-color: inherit !important;
}
#chart {
  height: 94%;
}
/* end ant design overrides */

.participants_data_wrapper {
  height: 50vh;
  overflow-y: auto;
}
.cou_con_par_tab_wrapper
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > :not(:first-child) {
  margin: 0 0 0 8px;
}

.slider_controls {
  position: absolute;
  right: 5px;
  top: 6px;
  border-radius: 4px;
  border: 0.4px solid #26a0fc;
  z-index: 9999;
  padding: 6px;
}
.avg_temp {
  padding: 1px 4px;
  border-radius: 3px;
  background-color: #3498db;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  transition: transform 0.5s, box-shadow 0.5s;
}

.avg_temp:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  background-color: #2980b9;
}

.participants_collapse_component > div > div,
.participants_collapse_component > div > div:nth-child(2) > div {
  padding: 0 !important;
  margin: 0 !important;
}
/* :where(.u_a_icon) >,
:where(.u_a_icon) {
  border-bottom: 0px !important;
} */
.ant-collapse-item {
  border: none !important;
}
/*  */
.pd_wrapper {
  /* margin-top: 8px; */
  border-radius: 8px;
  padding: 10px;
  height: 94%;
  overflow-y: auto;
}
.pd_header {
  /* display: flex;
  justify-content: space-between; */
  position: absolute;
}

.pd_user_avatar {
  display: flex;
  justify-content: center;
  font-family: var(--font-default) !important;
  font-weight: bold;
}
.pd_user_avatar_size {
  height: 56px;
  width: 56px;
  /* font-weight: 400; */
}

.pd_company_logo {
  position: absolute;
  /* left: 53%;
  top: 15%; */
  border-radius: 50%;
  /* bottom: 186px; */
  height: 16px;
  width: 16px;
  margin-left: 32px;
  margin-top: 10%;
}
.pd_company_logo_non_stageview {
  position: absolute;
  /* left: 53%;
  top: 15%; */
  border-radius: 50%;
  /* bottom: 186px; */
  height: 16px;
  width: 16px;
  margin-left: 32px;
  margin-top: 10%;
}
/* :where(.u_a_icon) {
  bottom: -6px !important;
  top: auto !important;
} */
.pd_user_name {
  text-align: center;
  font-weight: 500;
  font-size: 13px;
}
.pd_job_title {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
}
.pd_role {
  background-color: #436feb;
  font-weight: 600;
  font-size: 12px;
  display: inline-block;
  border-radius: 24px;
  padding: 2px 8px;
  font-style: italic;
}
.pd_flex_jc_center {
  display: flex;
  justify-content: center;
}
.pd_flex_jc_sb {
  display: flex;
  justify-content: space-between;
}
.pd_flex {
  display: flex;
}
.pd_flex_ai_center {
  display: flex;
  align-items: center;
}
.pd_t_sm {
  font-size: 12px;
  font-weight: 400;
}
.pd_t_sm_b {
  font-size: 12px;
  font-weight: 500;
}
.pd_font_bold {
  font-weight: 500;
}
.pd_ml_sm {
  padding-left: 4px;
}
.pd_mt_sm {
  margin-top: 8px;
}
.pd_mt_md {
  margin-top: 16px;
}
.pd_full_width {
  width: 100%;
}

.pd_text_left {
  text-align: left;
}
.pd_text_right {
  text-align: right;
}
.pd_border_tb {
  border-top: 1px solid rgba(154, 159, 165, 0.25);
  border-bottom: 1px solid rgba(154, 159, 165, 0.25);
}
.pd_border_l {
  border-left: 1px solid rgba(154, 159, 165, 0.25);
}
.pd_cursor {
  cursor: pointer;
}

/* global css */
.text_xxl {
  font-size: var(--font-size-xxl);
}
.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.pt-2 {
  padding-top: 8px;
}
.pt-4-i {
  padding-top: 16px !important;
}
.pb-2 {
  padding-bottom: 8px;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.m-1 {
  margin: 4px;
}

.m-2 {
  margin: 8px;
}
.mx-1 {
  margin-left: 4px;
  margin-right: 4px;
}

.border {
  border: 0.4px solid #ccc;
}

.rounded {
  border-radius: 4px;
}

.p-2 {
  padding: 8px;
}

.px-2 {
  padding-left: 8px;
  padding-right: 8px;
}

.px-1 {
  padding-left: 4px;
  padding-right: 4px;
}

.pt-4 {
  padding-top: 16px;
}
.pb-50-px {
  padding-bottom: 50px;
}
.pb-80-px {
  padding-bottom: 80px;
}
.mt-n2 {
  margin-top: -8px;
}
.mt-4 {
  margin-top: 16px;
}
.mt-6 {
  margin-top: 24px;
}

.height-inherit {
  height: inherit;
}

.height-100 {
  height: 100%;
}
.height-94 {
  height: 94%;
}
.overflowY-auto {
  overflow-y: auto;
}

.center-xy {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-light {
  color: #6f767e !important;
}
.border-light {
  border: 1px solid rgba(140, 140, 140, 0.35) !important;
}
.border_top_none {
  border-top: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.border_bottom_none {
  border-bottom: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important ;
}

/* map info  */
.gm-style-iw-chr {
  position: absolute;
  right: 0;
  top: -8px;
}
.gm-style-iw-d {
  margin-top: 10px;
}
.gm-style-iw-chr > button > span {
  height: 16px !important;
  width: 16px !important;
}
.bg_secondary_1 {
  background-color: var(--color-secondary-1);
}

#verified_user_loader {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* =============== MEDIA QUERY ============================ */
/* extra small */
@media (max-width: 576px) {
  #toggleViewAppWrapper {
    display: block !important;
    width: 100%;
  }
  #toggleViewAppWrapper > div {
    margin-bottom: 4px !important;
  }
  .apexcharts-datalabel-label,
  .apexcharts-datalabel-value {
    /* font-size: var(--font-size-xs) !important; */
    font-weight: var(--font-weight-bold);
    font-family: var(--font-default);
  }
  .full_map_maprticipant {
    width: 100% !important;
    left: 2px !important;
    right: 2px !important;
    width: 100% !important;
    bottom: 46px !important;
  }
  .pd_wrapper {
    /* margin-top: 10px; */
    height: auto !important;
    /* margin-bottom: 100px; */
  }
  #chart {
    height: 300px !important;
    margin-top: 10px;
    margin-bottom: 100px;
  }
  .full_screen_ts {
    justify-content: left !important;
  }
  .text_xxl {
    font-size: var(--font-size-md) !important;
  }
  .apexcharts-title-text {
    font-size: var(--font-size-md) !important;
  }
  .list_of_participants {
    max-height: 45vh;
  }
}

/* Small */
@media (min-width: 576px) {
  .apexcharts-datalabel-label,
  .apexcharts-datalabel-value {
    font-size: var(--font-size-sm) !important;
    font-weight: var(--font-weight-bold);
    font-family: var(--font-default);
  }
  .font_md {
    font-size: var(--font-size-md);
  }

  /* .ParticipantItemGridView {
    width: 100%;
  } */
  .apexcharts-title-text {
    font-size: var(--font-size-md) !important;
  }
  .apexcharts-title-text {
    font-size: var(--font-size-md) !important;
  }
}

/* Medium */
@media (min-width: 768px) {
  .apexcharts-datalabel-label,
  .apexcharts-datalabel-value {
    /* font-size: var(--font-size-md) !important; */
    font-weight: var(--font-weight-bold);
    font-family: var(--font-default);
  }
  .apexcharts-title-text {
    font-size: var(--font-size-xl) !important;
  }
}

/* Large */
@media (min-width: 992px) {
  .apexcharts-datalabel-label,
  .apexcharts-datalabel-value {
    font-size: var(--font-size-lg) !important;
    font-weight: var(--font-weight-bold);
    font-family: var(--font-default);
  }
  .font_md {
    font-size: var(--font-size-lg-1);
  }
}
/* custom sized media query */
@media (max-width: 1100px) and (min-width: 992px) {
  .list_of_participants {
    height: 37vh !important;
  }
  .list_of_participants_full_screen {
    height: 72vh !important;
  }
  #chart {
    height: 91%;
  }
  .apexcharts-datalabel-label,
  .apexcharts-datalabel-value {
    font-size: var(--font-size-md) !important;
    font-weight: var(--font-weight-bold);
    font-family: var(--font-default);
  }
  .pd_wrapper {
    height: 67%;
  }
  .text_xxl {
    font-size: var(--font-size-lg);
  }
}

/* Extra Large */
@media (min-width: 1200px) {
  .apexcharts-datalabel-label,
  .apexcharts-datalabel-value {
    font-size: var(--font-size-xl) !important;
    font-weight: var(--font-weight-bold);
    font-family: var(--font-default);
  }
  .font_md {
    font-size: var(--font-size-xl);
  }
  .apexcharts-title-text {
    font-size: var(--font-size-xxl) !important;
  }
}
/* Extra Extra Large */
@media (min-width: 1400px) {
  .apexcharts-datalabel-label,
  .apexcharts-datalabel-value {
    font-size: var(--font-size-xxl) !important;
    font-weight: var(--font-weight-bold);
    font-family: var(--font-default);
  }
  /* .list_of_participants {
    height: 46vh !important;
  } */
  /* #chart {
    height: 94% !important;
  } */
  /*
  .list_of_participants_grid {
    height: 44.5vh !important;
  } */
}
/* Extra Extra Large 1 */
@media (min-width: 1500px) {
  .apexcharts-datalabel-label,
  .apexcharts-datalabel-value {
    font-size: var(--font-size-xxl) !important;
    font-weight: var(--font-weight-bold);
    font-family: var(--font-default);
  }
  .list_of_participants {
    height: 49vh !important;
  }
  #chart {
    height: 95% !important;
  }
}
