:root {
  /* font */
  --font-default: -apple-system, BlinkMacSystemFont, "Segoe UI", system-ui,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Web", sans-serif;
  /* color */
  --color-primary: #2a85ff;
  --color-secondary: #292929;
  -color-light: #f1f1f1;
  -color-white: #ffffff;
  --color-dark: #000000;
  --color-danger: #d13438;
  --color-secondary-1: #383d3f;
  /* font size */
  --font-size-xs: 8px;
  --font-size-sm: 10px;
  --font-size-md: 12px;
  --font-size-lg: 14px;
  --font-size-lg-1: 15px;
  --font-size-xl: 16px;
  --font-size-xxl: 18px;
  /* font weight */
  --font-weight-thin: 300;
  --font-weight-normal: 400;
  --font-weight-bold: 500;
  --font-weight-bolder: 600;
  /* width */
  --width-screen: 100vh;
  --width-full: 100%;
  /* height */
  --height-screen: 100vh;
  --height-full: 100%;
  /* sizes */
  --zero: 0px;
  --xs: 2px;
  --sm: 4px;
  --md: 6px;
  --lg: 8px;
}
